
.content-div-1{
    justify-content: space-around;
    display: flex;width: 50%;
}



ion-card {
    -webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
    border:solid 1px #aaaaaa !important;
    border-radius: 2px;
  
}

.react-datepicker {
    z-index: 9999 !important;
}


.alert-tappable.alert-radio {
    height: auto;
    contain: content;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
    white-space: normal;
}

.modal-wrapper, .modal-shadow{
    border-radius: var(--border-radius);
    width: fit-content !important; 
    min-width: auto;
    max-width: auto;
  height: fit-content !important; 
    min-height: auto;
    max-height: auto;
    border-width: var(--border-width);
    border-style: var(--border-style);
    border-color: var(--border-color);
    background: var(--background);
    box-shadow: var(--box-shadow);
    overflow: var(--overflow);
    z-index: 10;
}
.col_logos{
    display: flex;
    justify-content:center ;
    align-items: center;
    
}

.button_css{
    margin: 25px 5px 25px 5px;
}



